<template>
  <onboarding-flow
    :step-id="stepId"
    :data="data"
    @update-data="onUpdateData"
    @change-step="onChangeStep"
  />
</template>
<script>
import Vue from 'vue';

import OnboardingFlow from './OnboardingFlow.vue';

export default Vue.extend({
  components: {
    OnboardingFlow,
  },

  layout: 'standalone',

  middleware: ['auth', 'verified', 'applicant'],

  data: () => ({
    data: null,
  }),

  computed: {
    stepId() {
      return this.$route.query.step;
    },
  },

  beforeMount() {
    this.fetch();
  },

  methods: {
    fetch() {
      const applicant = this.$auth.me;

      this.data = {
        applicant,
      };
    },

    onUpdateData(data) {
      if (data.applicant) {
        this.$auth.commit('PATCH_ME', { ...data.applicant });
        this.data.applicant = {
          ...this.data.applicant,
          ...data.applicant,
        };
      }
    },

    onChangeStep(step) {
      this.$router.push({
        name: this.$RouteEnum.APPLICANT_ONBOARDING,
        query: {
          step: step.slug,
        },
      });
    },
  },
});
</script>
