<template>
  <t-split-screen wide centered>
    <div v-if="!step">
      <t-spinner />
    </div>
    <template v-else>
      <nuxt-link v-if="abortRoute" class="abort-link" :to="{ name: abortRoute }">
        {{ abortText }}
      </nuxt-link>
      <transition name="step-slide-fade" mode="out-in">
        <component
          :is="step.component"
          v-bind="getPropsForStepComponent(step)"
          :step="step"
          @update-data="onUpdateData"
          @prev="onPrev"
          @next="onNext"
        />
      </transition>
      <template slot="secondary">
        <flow-split-screen-progress
          v-if="stepsCount > 1"
          :current="stepIndex"
          :count="stepsCount"
        />
        <transition name="step-fade" mode="out-in">
          <dashboard-image :key="step.image" :name="step.image" />
        </transition>
      </template>
    </template>
  </t-split-screen>
</template>
<script>
import FlowComponent from './Flow.vue';
import FlowSplitScreenProgress from './FlowSplitScreenProgress';
import DashboardImage from '~/components/DashboardImage';

export default FlowComponent.extend({
  components: {
    FlowSplitScreenProgress,
    DashboardImage,
  },

  data: () => ({
    abortRoute: null,
    abortText: null,
  }),

  methods: {
    onComplete() {},
  },
});
</script>
<style lang="scss" scoped>
@use 'sass:math';

.t-split-screen::v-deep .t-split-screen__primary {
  position: relative;
}

.t-split-screen::v-deep .t-split-screen__secondary {
  position: relative;
  z-index: 3;
}

.flow-progress {
  position: absolute;
  left: -#{math.div($flow-progress-circle-size, 2)};
  top: 50%;
  transform: translateY(-50%);
}

.abort-link {
  position: absolute;
  top: $s-4;
  right: $s-4;
}

.step-slide-fade-enter-active {
  transition: all 0.15s ease-out;
}

.step-slide-fade-leave-active {
  transition: all 0.15s ease-in;
}

.step-slide-fade-enter {
  transform: translateY(100px);
  opacity: 0;
}

.step-slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}

.step-fade-enter-active,
.step-fade-leave-active {
  transition: opacity 0.15s;
}
.step-fade-enter,
.step-fade-leave-to {
  opacity: 0;
}
</style>
