<script>
import Vue from 'vue';
import AbstractMethodError from '~/errors/AbstractMethodError';

export default Vue.extend({
  props: {
    stepId: {
      type: String,
      required: false,
      default: null,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      flow: this.getFlow(),
    };
  },

  computed: {
    step() {
      if (!this.stepId) {
        return null;
      }
      return this.flow.find(this.stepId);
    },

    stepIndex() {
      if (!this.step) {
        return 0;
      }

      return this.flow.findIndex(this.step.id);
    },

    stepsCount() {
      return this.flow.count();
    },
  },

  watch: {
    step: {
      handler() {
        if (!this.step) {
          const lastValid = this.flow.getLastValid(this.data, false);
          this.changeStep(lastValid);
          return;
        }

        this.validateStep(this.step);
      },

      immediate: true,
    },
  },

  methods: {
    getFlow() {
      throw new AbstractMethodError();
    },

    getPropsForStepComponent(step) {
      const obj = {};

      step.props.forEach((prop) => {
        obj[prop] = this.data[prop];
      });

      return obj;
    },

    getStepsComponentsMap() {
      throw new AbstractMethodError();
    },

    validateStep(step) {
      if (this.flow.isValid(this.data, step)) {
        return true;
      }

      this.changeStep(this.flow.getLastValid(this.data));
      return false;
    },

    changeStep(step) {
      const valid = this.validateStep(step);
      if (valid) {
        this.$emit('change-step', step);
      }
    },

    onPrev() {
      if (!this.step || !this.step.prev) {
        throw new Error('Invalid prev value');
      }

      this.changeStep(this.step.prev);
    },

    onNext() {
      if (!this.step) {
        throw new Error('Invalid next value');
      }

      if (!this.step.next) {
        this.onComplete();
        return;
      }

      this.changeStep(this.step.next);
    },

    onComplete() {
      throw new AbstractMethodError();
    },

    onUpdateData(newData) {
      this.$emit('update-data', {
        ...this.data,
        ...newData,
      });
    },
  },
});
</script>
<style lang="scss">
.flow {
}
</style>
