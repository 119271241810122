<script>
import buildFlow from './buildOnboardingFlow';
import FlowSplitScreen from '~/components/Flows/FlowSplitScreen';
import RouteEnum from '~/enums/RouteEnum';

export default FlowSplitScreen.extend({
  data() {
    return {
      abortRoute: RouteEnum.LOGOUT,
      abortText: this.$t('global.actions.logout'),
    };
  },

  methods: {
    getFlow() {
      return buildFlow();
    },

    async onComplete() {
      await this.$router.push({
        name: this.$RouteEnum.DASHBOARD,
      });
    },
  },
});
</script>
