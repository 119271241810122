<template>
  <div class="flow-progress" :style="style">
    <div
      v-for="step in range"
      :key="step"
      class="flow-progress__step"
      :class="getClasses(step)"
      :style="stepStyle"
    >
      <div class="flow-progress__step__line flow-progress__step__line--prev" />
      <div class="flow-progress__step__dot">
        <span class="flow-progress__step__dot__text">
          <t-icon v-if="step < current" icon="check" />
          <template v-else>{{ step + 1 }}</template>
        </span>
      </div>
      <div class="flow-progress__step__line flow-progress__step__line--next" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    current: {
      type: Number,
      required: true,
    },

    count: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      // current: 0,
    };
  },

  computed: {
    range() {
      const range = [];
      let i;

      for (i = 0; i < this.count; i++) {
        range.push(i);
      }

      return range;
    },

    style() {
      const preferredLineHeight = 48;
      const preferredCircleSize = 38;
      return {
        height: `${this.count * (preferredLineHeight * 2 + preferredCircleSize)}px`,
      };
    },

    stepStyle() {
      return {
        height: `${100 / this.count}%`,
      };
    },
  },

  methods: {
    getClasses(step) {
      const classes = [];
      if (step < this.current) {
        classes.push('flow-progress__step--completed');
      }

      if (step === this.current) {
        classes.push('flow-progress__step--current');
      }

      return classes;
    },
  },
});
</script>

<style lang="scss">
@use 'sass:math';

.flow-progress {
  $size: 30px;
  $half-size: math.div($size, 2);
  $line-width: 2px;
  $circle-size: $flow-progress-circle-size;
  $overlap-line: math.div(($circle-size - $size), 2);
  $line-transition: cubic-bezier(0.175, 0.885, 0.32, 2);
  height: 80vh;
  max-height: 80vh;
  z-index: 2;

  &__step {
    $self: &;

    position: relative;
    width: $circle-size;
    min-height: $size + 20px;

    &__dot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: $size;
      height: $size;
      display: flex;
      background-color: var(--grey-color);
      border-radius: 50%;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        border-radius: 50%;
        transform: scale(0);

        z-index: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border: 2px solid var(--primary-color);
        border-radius: 50%;
        //transform: scale(0);
        width: 0;
        height: 0;
        opacity: 0;
        z-index: 2;
        background-color: transparent;
      }

      &__text {
        position: relative;
        margin: auto;
        color: #fff;
        z-index: 1;
      }
    }

    &__line {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      width: $line-width;
      height: calc(50% - #{$half-size});

      &:before,
      &:after {
        content: ' ';
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #ccc;
      }

      &:after {
        height: 0;
        background-color: var(--primary-color);
      }

      &--prev {
        top: 0;
      }

      &--next {
        top: calc(50% + #{$half-size});
      }
    }

    &:first-child {
      #{$self} {
        &__line {
          &--prev {
            display: none;
          }
        }
      }
    }

    &:last-child {
      #{$self} {
        &__line {
          &--next {
            display: none;
          }
        }
      }
    }

    &--completed {
      #{$self} {
        &__dot {
          background-color: var(--primary-color);
        }

        &__line {
          &--prev {
            &:after {
              height: 100%;
            }
          }

          &--next {
            &:after {
              height: 100%;
            }
          }
        }
      }
    }

    &--current {
      #{$self} {
        &__dot {
          &:before {
            transform: scale(1);
            transition: transform 0.1s ease-out 0.1s;
          }

          &:after {
            width: $circle-size;
            height: $circle-size;
            opacity: 1;
            transition: opacity 0.1s ease-out 0.1s, width 0.1s ease-out 0.1s,
              height 0.1s ease-out 0.1s;
          }
        }

        &__line {
          &--prev {
            &:before,
            &:after {
              height: calc(100% - #{$overlap-line});
            }

            &:after {
              transition: height 0.2s $line-transition;
            }
          }

          &--next {
            top: calc(50% + #{$half-size} + #{$overlap-line});

            &:before,
            &:after {
              height: calc(100% - #{$overlap-line});
            }

            &:after {
              transition: height 0.2s $line-transition 0.3s;
            }
          }
        }
      }
    }
  }
}
</style>
